<script>
import ResourceDetail from '@shell/components/ResourceDetail';
import PaiRedirect from '@/pkg/pai/utils/pai-redirect';

export default {
  name:       'ClusterResourceNamespaceId',
  components: { ResourceDetail },
  middleware: PaiRedirect(),
};
</script>

<template>
  <ResourceDetail />
</template>
