const PAI_PRODUCTS = ['pai', 'deploy', 'platform'];

export default function() {
  return function middleware({
    redirect,
    route,
    store,
  }) {
    const product = store.getters['currentProduct'].name;

    if (PAI_PRODUCTS.includes(product)) {
      route.name = `${ product }-${ route.name.replace('-product', '') }`;

      return redirect(route);
    }
  };
}
